// Xena License Server admin, HH 2020

<template>
    <v-main id="statistics-id" class="section-style" style="padding: 0px; width: 400px">

        <v-card-title>
            <h1> Statistics </h1>
            <v-spacer/>
            <v-btn
                outlined
                :disabled = "!show_prop"
                color     = "info"
                @click    = "$emit ( 'refresh-event' )"
            >
                REFRESH
            </v-btn>
        </v-card-title>

        <div v-if="show_prop">

            <v-card-text v-for="(info,restype) in statistics_prop.resource_types" :key="restype">
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-left">
                              <h3 class="resource-style"><b> {{ restype }} </b></h3>
                            </th>
                            <th class="text-right"> Requests </th>
                            <th class="text-right"> Units    </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left">  Granted                       </td>
                            <td class="text-right"> {{ info.requests.granted }}   </td>
                            <td class="text-right"> {{ info.units.granted   }}    </td>
                        </tr>
                        <tr>
                            <td class="text-left">  Refreshed                     </td>
                            <td class="text-right"> {{ info.requests.refreshed }} </td>
                            <td class="text-right"> {{ info.units.refreshed   }}  </td>
                        </tr>
                        <tr>
                            <td class="text-left">  Freed                         </td>
                            <td class="text-right"> {{ info.requests.freed }}     </td>
                            <td class="text-right"> {{ info.units.freed   }}      </td>
                        </tr>
                        <tr>
                            <td class="text-left">  Reclaimed                     </td>
                            <td class="text-right"> {{ info.requests.reclaimed }} </td>
                            <td class="text-right"> {{ info.units.reclaimed   }}  </td>
                        </tr>
                        <tr>
                            <td class="text-left">  Denied                        </td>
                            <td class="text-right"> {{ info.requests.denied }}    </td>
                            <td class="text-right"> {{ info.units.denied   }}     </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>

            <v-card-text>
                <v-simple-table dense>
                    <tbody>
                        <tr v-for="(value,name) in statistics_prop.error_stats" :key="name">
                            <td class="text-left">  {{ nice_name ( "error", name ) }} </td>
                            <td class="text-right"> {{ value }}                       </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>

        </div>

    </v-main>
</template>

<script>

    export default
    {
        name: "statistics-comp",

        props:
        {
            show_prop:          Boolean,
            statistics_prop:    Object
        },

        methods:
        {
            nice_name: function ( p, n )
            {
                return n.replace ( "stat", p ).replace ( /_/g, " " );
            }
        }
    };

</script>
