// Xena License Server admin, HH 2020

<template>
    <v-main id="login-id" class="section-style" style="padding: 0px; width: 400px">

        <v-card-title>
            <h1> Login </h1>
        </v-card-title>

        <v-card-text>
            <v-text-field
                label   = "Account id"
                v-model = "credentials_prop.account_id"
                class   = "input-style"
                :type   = "show_aid ? 'text' : 'password'"
                prepend-icon        = "mdi-account-circle"
                :append-outer-icon  = "show_aid ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-outer = "show_aid = !show_aid"
            />
            <v-text-field
                label   = "License server id"
                v-model = "credentials_prop.equipment_id"
                class   = "input-style"
                prepend-icon = "mdi-account-circle"
            />
            <v-text-field
                label   = "License server key"
                v-model = "credentials_prop.equipment_key"
                class   = "input-style"
                :type   = "show_key ? 'text' : 'password'"
                prepend-icon        = "mdi-lock"
                :append-outer-icon  = "show_key ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-outer = "show_key = !show_key"
            />
        </v-card-text>

        <h3 v-if="message_prop" class="message-style"> {{ message_prop }} </h3>

        <v-card-actions>
            <v-btn color="info" :disabled="logged_in_prop" @click="click_login">
                LOGIN
            </v-btn>
            <v-spacer/>
            <v-btn color="info" :disabled="!logged_in_prop" @click="click_logout" outlined>
                LOGOUT
            </v-btn>
        </v-card-actions>

        <v-card-text v-if="has_available">
            <v-simple-table dense>
                <thead>
                    <tr>
                        <th class="text-left">  Resource type       </th>
                        <th class="text-right"> Available units now </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(units,restype) in available_prop" :key="restype">
                        <td class="text-left resource-style"><h3> {{ restype  }} </h3></td>
                        <td class="text-right">                   {{ units }}         </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>

    </v-main>
</template>

<script>

    export default
    {
        name: "login-comp",

        props:
        {
            credentials_prop:   Object,
            logged_in_prop:     Boolean,
            message_prop:       String,
            available_prop:     Object
        },

        data: function ()
        {
            return {
                show_aid:   false,
                show_key:   false
            };
        },

        computed:
        {
            has_available: function ()
            {
                return Object.keys ( this.available_prop ).length > 0;
            }
        },

        methods:
        {
            click_login: function ()
            {
                this.show_aid  = false;
                this.show_key  = false;
                this.$emit ( 'login-event' );
            },

            click_logout: function ()
            {
                this.show_aid  = true;
                this.$emit ( 'logout-event' );
            }
        }
    };

</script>
