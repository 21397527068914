// Xena License Server admin, HH 2020

import Vue      from 'vue'
import vuetify  from './plugins/vuetify';

Vue.config.productionTip = false

import main_vue from './main.vue'

console.log ( "Server admin app, mode:", process.env.NODE_ENV, ", base:", process.env.BASE_URL );

new Vue
(
    {
        el: "#app-id",
        vuetify,
        render: h => h ( main_vue )
    }
);
