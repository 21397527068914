// Xena License Server admin, HH 2020

<template>
    <v-main id="licenses-id" class="section-style" style="padding: 0px">

        <v-card-title>
            <h1> Licenses </h1>
            <v-spacer/>
            <h4 v-if="show_prop">
                 License file id: F{{ licenses_prop.file_id }}
            </h4>
            <v-spacer/>
            <v-btn
                outlined
                :disabled = "!show_prop"
                color     = "info"
                @click    = "$emit ( 'refresh-event' )"
            >
                REFRESH
            </v-btn>
        </v-card-title>

        <div v-if="show_prop">

            <v-card-text v-for="(info,restype) in licenses_prop.resource_types" :key="restype">
                <v-divider/>
                <v-card-actions>
                    <h3 class="resource-style"> {{ restype }} </h3>
                    <v-spacer/>
                    <h4> Licensed units today: {{ info.total_units_today }} </h4>
                </v-card-actions>
                <v-card-text>
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th class="text-left">  Id    </th>
                                <th class="text-left">  Type  </th>
                                <th class="text-left">  Start </th>
                                <th class="text-right"> Days  </th>
                                <th class="text-right"> Units </th>
                                <th class="text-left">  Valid </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="license in info.licenses" :key="license.id">
                                <td class="text-left"> L{{ license.id    }} </td>
                                <td class="text-left">  {{ license.type  }} </td>
                                <td class="text-left">  {{ license.start }} </td>
                                <td class="text-right"> {{ license.days  == 0 ? "-" : license.days  }} </td>
                                <td class="text-right"> {{ license.units == 0 ? "-" : license.units }} </td>
                                <td><v-icon v-if="license.valid == 'true'"> {{ "mdi-check" }} </v-icon></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card-text>

            <v-card-actions>
                <input
                    type    = "file"
                    ref     = "file-ref"
                    style   = "display: none"
                    @change = "choose_file"
                >
                <v-btn
                    color  = "info"
                    @click = "$refs [ 'file-ref' ].click ()"
                >
                    UPLOAD
                </v-btn>
                <v-spacer/>
            </v-card-actions>

        </div>

    </v-main>
</template>

<script>

    export default
    {
        name: "licenses-comp",

        props:
        {
            show_prop:      Boolean,
            licenses_prop:  Object
        },

        methods:
        {
            choose_file: function ()
            {
                this.$emit ( "upload-event", event.target.files [ 0 ] );
            }
        }
    };

</script>
