// Xena License Server admin, HH 2020

<template>
    <v-main id="grants-id" class="section-style" style="padding: 0px">

        <v-card-title>
            <h1> Grants </h1>
            <v-spacer/>
            <v-btn
                outlined
                :disabled = "!show_prop"
                color     = "info"
                @click    = "$emit ( 'refresh-event' )"
            >
                REFRESH
            </v-btn>
        </v-card-title>

        <v-card-text v-for="(info,restype) in grants_cleaned" :key="restype">
            <v-divider/>
            <v-card-actions>
                <h3 class="resource-style"> {{ restype }} </h3>
                <v-spacer/>
                <h4> Available units now: {{ available_prop [restype] }} </h4>
            </v-card-actions>
            <v-card-text>
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-left">  Serial no  </th>
                            <th class="text-left">  Chassis    </th>
                            <th class="text-left">  Deployment </th>
                            <th class="text-left">  Email      </th>
                            <th class="text-left">  Source     </th>
                            <th class="text-right"> Units      </th>
                            <th class="text-right"> At         </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="grant in info" :key="grant.serial_no">
                            <td class="text-left">  {{ grant.serial_no    }} </td>
                            <td class="text-left">  {{ grant.chassis_name }} </td>
                            <td class="text-left">  {{ grant.deploy_info  }} </td>
                            <td class="text-left">  {{ grant.email_info   }} </td>
                            <td class="text-left">  {{ grant.source       }} </td>
                            <td class="text-right"> {{ grant.units        }} </td>
                            <td class="text-right"> {{ grant.grant_time   }} </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card-text>

    </v-main>
</template>

<script>

    export default
    {
        name: "grants-comp",

        props:
        {
            show_prop:      Boolean,
            available_prop: Object,
            grants_prop:    Object
        },

        computed:
        {
            grants_cleaned: function ()
            {
                const c = JSON.parse ( JSON.stringify ( this.grants_prop ) );
                return c;
            }
        }

    };

</script>
